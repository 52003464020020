<template>
  <Breadcrumb
    v-if="permissionGroupData"
    :detailTitle="permissionGroupData.name"
  />
  <Grid :settings="gridSettings" :columns="gridColumns" />
</template>
<script>
export default {
  name: "PermissionGroupDetail",
  components: {},
  data() {
    return {
      permissionGroupData: {},
      gridSettings: {
        action: "PermissionGroupDetail",
        requestUrl: String.format("/Lcdp-GetUsersFromPermissionGroup"),
        requestUrlRouteParamNames: [{ key: "id", value: "permissionGroupId" }],
        routeRequiredParameters: [],

        isGatewayRequest: true,

        isPublicIdColumnHide: true,
        isCommandColumnHide: true,
        allowSearchPanel: true,
        allowExcelExport: false,
        allowDragAndDrop: false,
        allowPaging: true,
        allowDeleting: false,
        autoOrderProcess: true,

        buttons: [],
      },
      gridColumns: [
        {
          text: this.$t(
            "Users.FirstName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "firstName",
          type: "string",
          visible: true,
          width: 300,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Users.LastName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "lastName",
          type: "string",
          visible: true,
          width: 300,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "Users.Email",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "email",
          type: "string",
          visible: true,
          width: 300,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.IsActive",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isActive",
          type: "boolean",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
      ],
    };
  },
  methods: {
    getPermissionGroup() {
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Lcdp-GetPermissionGroup?id={0}",
            this.$route.params.permissionGroupId
          )
        )
        .then((response) => {
          if (response.data !== null) {
            this.permissionGroupData = response.data;
          }
        })
        .catch(function (error) {});
    },
  },
  mounted() {
    this.getPermissionGroup();
  },
};
</script>
